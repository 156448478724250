<template>
    <div class="app text-center">
        <v-dialog
        persistent
        v-model="show"
        :width="width ?? 400">
        <v-card>
            <v-card-title class="text-h5 primary white--text">{{ $t('confirmmessage.title') }}</v-card-title>

            <v-card-text class="pa-5 text-center font-weight-bold">{{ msg }}</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" @click="onNoClick" width="100">{{ $t('confirmmessage.no_button') }}</v-btn>
                <v-btn class="white--text" color="primary" @click="onYesClick" width="100">{{ $t('confirmmessage.yes_button') }}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
  name: "ShowConfirm",
  methods: {
    onNoClick() {
      this.show = false;
      if (this.no_callback != null) {
        this.no_callback();
      }
    },
    onYesClick() {
      this.show = false;
      if (this.yes_callback != null) {
        this.yes_callback();
      }
    }
  },
  props: {
     value: Boolean,
     msg: String,
     width: Number,
     no_callback: null,
     yes_callback: null
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
};
</script>

<style scoped>
.v-btn {
  text-transform:none !important;
}
</style>